import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HomeHoverWords from '../components/HomeHoverWords';
import Footer from '../components/Footer';
import './index.scss';
import isActive from '../pipes/users';

// hybrid_practice
import image3_1 from '../images/home-images/hybrid_practice/envelope-diagrams_01.svg';

import lottie from 'lottie-web';
import {getWindow, isBrowser} from '../helpers/utils';
import logoData from '../images/envelopeshort'

let mapLinks = {
    'architecture': {
        count: 0,
        link: '/work/type/architecture',
        images: [
            {
                src: 'image1_1',
                styles: {
                    top: '16%',
                    left: '25%',
                    width: '55%',
                },
            },
        ],
    },
    'activations': {
        link: '/work/type/activations',
        count: 0,
        images: [
            {
                src: 'image2_1',
                styles: {
                    top: '3%',
                    width: '39%',
                    left: '12%',
                },
            },
            {
                src: 'image2_2',
                styles: {
                    top: '46%',
                    left: '57%',
                    width: '39%',
                },
            },
        ],

    },
    'hybrid': {
        link: '/studio/philosophy',
        count: null,
        images: [
            {
                src: {source: image3_1},
                styles: {
                    top: '-8%',
                    left: '45%',
                    width: '45%',
                },
            },
        ],
        sameAs: [ 'practice' ],
    },
    'practice': {
        link: '/studio/philosophy',
        count: null,
        images: [
            {
                src: {source: image3_1},
                styles: {
                    top: '-8%',
                    left: '45%',
                    width: '45%',
                },
            },
        ],
        sameAs: [ 'hybrid' ],
    },
    'experimental': {
        link: '/work/idea/experimental',
        count: 0,
        images: [
            {
                src: 'image4_1',
                styles: {
                    top: '57%',
                    left: '75%',
                    width: '15.5%',
                },
            },
            {
                src: 'image4_2',
                styles: {
                    top: '4%',
                    left: '59%',
                    width: '15%',
                },
            },
            {
                src: 'image4_3',
                styles: {
                    top: '46.5%',
                    left: '11.5%',
                    width: '38%',
                },
            },
        ],
    },
    'flexible': {
        link: '/work/idea/flexible',
        count: 0,
        images: [
            {
                src: 'image5_1',
                styles: {
                    top: '46%',
                    left: '57%',
                    width: '39%',
                },
            },
            {
                src: 'image5_2',
                styles: {
                    top: '3%',
                    left: '11.5%',
                    width: '39%',
                },
            },
        ],
    },
    'collaborative': {
        count: 0,
        link: '/studio/collaborators',
        images: [
            {
                src: 'image6_1',
                styles: {
                    top: '4%',
                    left: '36.5%',
                    width: '14.5%',
                },
            },
            {
                src: 'image6_2',
                styles: {
                    top: '46.5%',
                    left: '56.8%',
                    width: '39%',
                },
            },
            {
                src: 'image6_3',
                styles: {
                    top: '58%',
                    left: '12%',
                    width: '15%',
                },
            },
        ],
    },
    'immersive': {
        link: '/work/idea/immersive',
        count: 0,
        images: [
            {
                src: 'image7_1',
                styles: {
                    top: '16%',
                    left: '26.5%',
                    width: '54%',
                },
            },
        ],
    },
    'holistic': {
        link: '/work/idea/systems',
        count: 0,
        images: [
            {
                src: 'image8_1',
                styles: {
                    top: '58%',
                    left: '12%',
                    width: '15%',
                },
            },
            {
                src: 'image8_2',
                styles: {
                    top: '4%',
                    left: '36%',
                    width: '15%',
                },
            },
            {
                src: 'image8_3',
                styles: {
                    top: '46%',
                    left: '57%',
                    width: '39%',
                },
            },
        ],
    },
    'communities': {
        link: '/work/idea/participatory',
        count: 0,
        images: [
            {
                src: 'image9_1',
                styles: {
                    top: '46%',
                    left: '11.5%',
                    width: '39.5%',
                },
            },
            {
                src: 'image9_2',
                styles: {
                    top: '6%',
                    left: '58.5%',
                    width: '37%',
                },
            },
        ],
    },
};


function getTagConfig(path, config) {

    path.forEach(partPath => {

        if (config && config.categories && config.categories[ `/${partPath}` ]) {
            config = config.categories[ `/${partPath}` ];
        }
    });

    return config;
}


let text = `Envelope is an idea-driven architecture and activations studio; a hybrid  practice intent on realizing an equitable and compelling built environment. Our process is collaborative and experimental, searching out flexible,  immersive, and holistic design solutions specific to the needs of our clients and communities.`;

let player = null;
let selector = 'index-logo';

class CustomSplashScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading: true, isOpen: true};
        this.showingAnimationOnStart = true;
    }

    componentWillMount() {
        this.showingAnimationOnStart = this.countSplashShowed < 1;
    }

    componentDidMount() {
        let container = document.querySelector(`#${selector}`);

        if (!container) {
            return null;
        }

        player = lottie.loadAnimation({
            container, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: logoData
        });
        /*
                this.setState({
                    isOpen: !getWindow().sessionStorage.getItem(`splash_index`)
                });
        */
        player.addEventListener('complete', () => {
            if (isBrowser()) {
                getWindow().sessionStorage.setItem(`splash_index`, this.countSplashShowed + 1);
                this.setState({isOpen: false});
            }
        });
    }

    get countSplashShowed() {
        return +(getWindow().sessionStorage.getItem(`splash_index`) || 0);
    }

    render() {
        let hasAnimation = this.countSplashShowed < 1;

        return (
            <div
                className={`IndexSplash ${hasAnimation ? '' : 'hide'} ${this.showingAnimationOnStart ? 'anim' : 'no-anim'}`}
                id={'home-splash-wrap'}>
                <div id={selector}/>
                {this.props.children}
            </div>);
    }
}

const IndexPage = ({pageContext, data}) => {
    let {collaborators} = data.cms;
    let {siteMap} = pageContext;
    let navigationMap = {};

    // add counters for "/work/idea/**" and "/work/type/**"
    Object.keys(mapLinks).forEach(link => {
        let linkConf = mapLinks[ link ];
        let path = linkConf.link.split('/').filter(path => path);
        let tagConf = getTagConfig(path, siteMap);

        navigationMap[ link ] = {
            ...linkConf,
            ...(typeof linkConf.count === 'number' ? {count: tagConf.count} : null),
        };
    });

    // counter for "collaborative"
    navigationMap[ 'collaborative' ].count = collaborators.filter(isActive).length;


    return (
        <div>
            <CustomSplashScreen>
                <Layout className="IndexPage">
                    <SEO title="Home" keywords={[]}/>
                    <HomeHoverWords text={text.split(' ')} mapLinks={navigationMap} allData={data}/>
                    <Footer/>
                </Layout>
            </CustomSplashScreen>
        </div>
    );
};

export const query = graphql`
{
  cms {
    collaborators {
      fullName
      active
    }
  }
  
  image1_1: file(relativePath: { eq: "home-images/architecture/Envelope_Forest House_01_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
  }
    
  image2_1: file(relativePath: { eq: "home-images/activations/Envelope_NOW Hunters Point_11_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
      
  image2_2: file(relativePath: { eq: "home-images/activations/Envelope_PROXY_05_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
        
  image4_1: file(relativePath: { eq: "home-images/experimental/Envelope_Expanded Field_06_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
          
  image4_2: file(relativePath: { eq: "home-images/experimental/Envelope_North Showroom BK_10_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
            
  image4_3: file(relativePath: { eq: "home-images/experimental/Envelope_PROXY_02_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
    
  image5_1: file(relativePath: { eq: "home-images/flexible/Envelope_JMS Annex_02_Alt_HomepageCropped.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
      
  image5_2: file(relativePath: { eq: "home-images/flexible/Envelope_Kindred_Alt_HomepageCropped.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
        
  image6_1: file(relativePath: { eq: "home-images/collaborative/Envelope_2214_vol 1_ed 3_04_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
          
  image6_2: file(relativePath: { eq: "home-images/collaborative/Envelope_French Laundry_11_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
            
  image6_3: file(relativePath: { eq: "home-images/collaborative/Envelope_Radhaus_08_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
              
  image7_1: file(relativePath: { eq: "home-images/immersive/Envelope_2214_vol 1_ed 1_08_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
                
  image8_1: file(relativePath: { eq: "home-images/holistic/Envelope_Forest House_04_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
                  
  image8_2: file(relativePath: { eq: "home-images/holistic/Envelope_French Laundry_07_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
                    
  image8_3: file(relativePath: { eq: "home-images/holistic/Envelope_North Showroom BK_26_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
                      
  image9_1: file(relativePath: { eq: "home-images/communities/Envelope_Hunters Point Shoreline_11_Alt_HomepageCropped.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
                        
  image9_2: file(relativePath: { eq: "home-images/communities/Envelope_NOW Hunters Point_18_Homepage.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90){
        ...GatsbyImageSharpFluid
      }
    }
  }
  

}`;

export default IndexPage;
