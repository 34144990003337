import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {Link} from 'gatsby';
import Hover from '../Hover';
import {replaceSpacesTo} from '../../helpers/textFormatter';
import Img from 'gatsby-image';

export default function HomeHoverWords({text, mapLinks, allData}) {
    let [ hoverText, setHoverText ] = useState('');

    return (
        <div className={`home-hover-words ${hoverText ? `${replaceSpacesTo(hoverText)} hover` : ''}`}>

            {text.map((word, index) => {
                let wordTrimmed = word.trim();
                let hasSymbolInEnd = /\W$/.test(wordTrimmed);
                let symbol = hasSymbolInEnd ? wordTrimmed.slice(-1) : '';
                wordTrimmed = hasSymbolInEnd ? wordTrimmed.slice(0, -1) : wordTrimmed;
                let isLink = mapLinks[ wordTrimmed ];
                let isHover = hoverText === wordTrimmed;

                if (!isLink) {
                    return (<span key={index.toString()}> {word} </span>);
                }

                if (!isHover && isLink.sameAs) {
                    isHover = isLink.sameAs.includes(hoverText);
                }

                let showCounter = typeof mapLinks[ wordTrimmed ].count === 'number';

                return (
                    <Fragment key={index.toString()}>
                        <Hover
                            className={isHover ? 'on-hover-word' : 'off-hover-word'}
                            onHover={isHover => setHoverText(isHover ? wordTrimmed : '')}>
                            {}
                            {<Link to={mapLinks[ wordTrimmed ].link || '/#'}>
                                {wordTrimmed}
                                {showCounter && <sup className={'index'}> {mapLinks[ wordTrimmed ].count}</sup>}
                            </Link>}
                        </Hover>
                        {symbol && <span>{symbol}</span>}
                    </Fragment>
                );
            })
            }

            {
                mapLinks[ hoverText ] && mapLinks[ hoverText ].images.map((image) => {
                    let src = allData[ image.src ] && allData[ image.src ].childImageSharp.fluid;

                    if (src) {
                        return (<Img fluid={src}
                                     key={image.src.toString()}
                                     style={image.styles}
                                     alt={hoverText}
                                     className="preview-category-image"
                        />);
                    }

                    return (
                        <img src={image.src.source}
                             style={image.styles}
                             alt={hoverText}
                             key={'555'}
                             className="preview-category-image"/>
                    );
                })
            }
        </div>);
}

HomeHoverWords.propTypes = {
    text: PropTypes.arrayOf(PropTypes.string).isRequired,
    mapLinks: PropTypes.objectOf(
        PropTypes.shape({
            count: PropTypes.number,
            images: PropTypes.arrayOf(
                PropTypes.shape({
                    src: PropTypes.any.isRequired,
                    styles: PropTypes.object,
                }),
            ).isRequired,
        }),
    ).isRequired,
};
